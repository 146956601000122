import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { VStack, Link } from "@chakra-ui/react"

const NotFoundPage = () => {
  return (
    <>
      <VStack height="70vh" justify="center" align="center">
        <h1>404: Not Found</h1>
        <Link as={GatsbyLink} to="/">
          Zurück zur Homepage.
        </Link>
      </VStack>
    </>
  )
}

export default NotFoundPage
